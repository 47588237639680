/* --- CSS --- */
import "../css/normalize.scss";
import "material-components-web/material-components-web.scss";
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import '@simonwep/pickr/dist/themes/classic.min.css';   // 'classic' theme
import '@simonwep/pickr/dist/themes/monolith.min.css';  // 'monolith' theme
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme
import "../css/layout.scss";
import "../css/style.scss";
import "../css/color-picker.css";
/* --- RESOURCES ---*/
import $ from "jquery";
import * as mdc from "material-components-web";

const page="#page";
let mdcAlert;
let mdcDialog;
let mdcSubDialog;
let mdctabBar=[];
let mdcFab=[];
let mdcSelects=[];
let mdcTextFields=[];
let mdcCheckboxs=[];
let mdcCheckboxFormFields=[];
let mdcRadios=[];
let mdcRadiosFormFields=[];
let digital=$(".digital-printing-container");
let screenBody=$(".screen-printing-body-container");
let screenCouvercleTop=$(".screen-printing-couvercle-top-container");
let screenCouvercleBottom=$(".screen-printing-couvercle-bottom-container");


function ajaxSubmit(node) {
	$.ajax({
		type: node.attr("method"),
		url: node.attr("action"),
		enctype: "multipart/form-data",
		data: new FormData(node[0]),
		processData: false,
		contentType: false,
		cache: false
	}).done(function(response, status, xhr) {
		console.info(response);
		if(response !== false) {
			/** @var response.redirect */
			if(typeof response.redirect !== "undefined") {
				/** @var response.redirect.url */
				/** @var response.redirect.timer */
				if(response.redirect.timer > 0) {
					if(response.redirect.url === "current") {
						setTimeout(function() {
							window.location.reload();
						}, response.redirect.timer);
					} else {
						setTimeout(function() {
							window.location.replace(response.redirect.url);
						}, response.redirect.timer);
					}
				} else {
					if(response.redirect.url === "current") {
						window.location.reload();
					} else {
						window.location.replace(response.redirect.url);
					}
				}
			}
			/** @var response.modal */
			if(typeof response.modal !== "undefined") {
				let appendContent=null;
				/** @var response.modal.action */
				/** @var response.modal.modal */
				/** @var response.modal.content */
				if(response.modal.modal === "mdcDialog") {
					if(response.modal.action === "load") {
						appendContent=new Promise(function(fulfill) {
							fulfill($("#dialog-level").find(".mdc-dialog__surface").append(response.modal.content));
						});
						appendContent.then(function() {
							mdcDialog.open();
						});
					} else if(response.modal.action === "unload") {
						mdcDialog.close();
					}
				} else if(response.modal.modal === "mdcSubDialog") {
					if(response.modal.action === "load") {
						appendContent=new Promise(function(fulfill) {
							fulfill($("#sub-dialog-level").find(".mdc-dialog__surface").append(response.modal.content));
						});
						appendContent.then(function() {
							mdcSubDialog.open();
						});
						mdcSubDialog.open();
					} else if(response.modal.action === "unload") {
						mdcSubDialog.close();
					}
				}
				appendContent=null;
			}
			/** @var response.functions */
			if(typeof response.fragments === "undefined" && typeof response.functions !== "undefined") {
				$.each(response.functions, function(func, params) {
					execFunction(func, params);
				});
			}
			/** @var response.fragments */
			if(typeof response.fragments !== "undefined") {
				$.each(response.fragments, function(key, object) {
					/** @var object.target */
					/** @var object.content */
					$(page).find(object.target).empty().append(object.content);
				});
				if(typeof response.functions !== "undefined") {
					$.each(response.functions, function(func, params) {
						execFunction(func, params);
					});
				}
			}
			/** @var response.toast */
			if(typeof response.toast !== "undefined") {
				$.each(response.toast, function(i, v) {
					messageInfo(v.message, v.type, v.length);
				});
			}
		}
	}).fail(function(request, status, error) {
		console.error(request);
		console.error(status);
		console.error(error);
		// console.error("Une erreur est survenue");
	});
}

function execFunction(func, params) {
	if(func === "mdcInit") {
		mdcInit(params);
	}
	if(func === "handleMainExtraFormButton") {
		handleMainExtraFormButton(params);
	}
	
}

function messageInfo(message, type, length) {
	let classe;
	let toastContainer = document.getElementById('toast-container');;
	if(type === "success") {
		classe="toast green";
	} else if(type === "error") {
		classe="toast red";
	} else if(type === "alert") {
		classe="toast orange";
	} else {
		classe="toast white";
	}
	if(typeof length === "undefined") {
		length=5000;
	}
	
	if (toastContainer !== null) {
		toastContainer.classList.add('open');
		toastContainer.innerHTML = `<div class="${classe}">${message}</div>`;
		setTimeout(function() {
			toastContainer.innerHTML = '';
			toastContainer.classList.remove('open');
		}, 5000);
	}
}

function handleCenterExtraPanel(node) {
	let mainContainer=$(page).find("#main-center-container");
	let centerHeaderLeft=$(page).find("#center-header-left-container");
	let centerExtraPanel=$(page).find("#center-extra-container");
	let target=node.attr("data-target");
	centerExtraPanel.find("[class*='app-extra-frame']").removeClass("show");
	if(node.parent().hasClass("in-stand-icon")) {
		node=centerHeaderLeft.find("i[data-target="+target+"]");
	}
	if(node.hasClass("active")) {
		node.removeClass("active");
		// centerExtraPanel.removeClass("open");
		mainContainer.removeClass("center-extra-open");
	} else {
		if($(centerHeaderLeft).find(".app-center-extra-control.active").length && !node.hasClass("active")) {
			centerHeaderLeft.find(".app-center-extra-control").removeClass("active");
		} else {
			// centerExtraPanel.addClass("open");
			mainContainer.addClass("center-extra-open");
		}
		node.addClass("active");
		centerExtraPanel.find(".app-extra-frame-"+target).addClass("show");
	}
}

function handleMainExtraFormButton(state) {
	if(state === true) {
		$(page).find("#main-extra-action .app-main-extra-form-submit").removeClass("hidden");
	} else {
		$(page).find("#main-extra-action .app-main-extra-form-submit").addClass("hidden");
	}
}
function mdcInit() {
	document.querySelectorAll(".mdc-text-field").forEach(function(node) {
		mdcTextFields.push(new mdc.textField.MDCTextField(node));
	});
	// tabBar init
	document.querySelectorAll(".mdc-tab-bar").forEach(function(node) {
		mdctabBar.push(new mdc.tabBar.MDCTabBar(node));
	});
	// Checkbox init
	// Checkboxs init
	document.querySelectorAll(".mdc-checkbox").forEach(function(node) {
		let checkbox=new mdc.checkbox.MDCCheckbox(node);
		mdcCheckboxs.push(checkbox);
		let formField=new mdc.formField.MDCFormField(node.parentElement);
		formField.input=checkbox;
		mdcCheckboxFormFields.push(formField);
	});
	document.querySelectorAll(".mdc-radio").forEach(function(node) {
		let radio=new mdc.radio.MDCRadio(node);
		mdcRadios.push(radio);
		let formField=new mdc.formField.MDCFormField(node.parentElement);
		formField.input=radio;
		mdcRadiosFormFields.push(formField);
	});
	// Alert init
	mdcAlert=new mdc.dialog.MDCDialog(document.querySelector("#alert-dialog"));
	mdcAlert.listen("MDCDialog:closed", function() {
		$(mdcAlert["root_"]).find(".mdc-dialog__content").empty();
	});
	// Fab init
	document.querySelectorAll(".mdc-fab").forEach(function(node) {
		mdcFab.push(new mdc.ripple.MDCRipple(node));
	});
	// Select init
	document.querySelectorAll(".mdc-select").forEach(function(node) {
		let select=new mdc.select.MDCSelect(node);
		select.listen("MDCSelect:change", function() {
			if(select.value.length > 0) {
				$(select.root_).find("select option[value="+select.value+"]").prop("selected", true);
			} else {
				$(select.root_).find("select option:first-child").prop("selected", true);
			}
			$(select.root_).find("select").trigger("change");
		});
		mdcSelects.push(select);
	});
}


function afficherMoulure(node) {
	let radioStraight = $(".radio-straight-body");
	if (node.is(':checked') ) {
		afficherOption('moulure', true);
		$('.mask-link').addClass("hidden");
		$('.moulure-on').addClass("hidden");
		if (radioStraight.hasClass("on")) {
			$(".radio-iso-beads").addClass("on");
			radioStraight.removeClass("on");
			$(".radio-iso-beads").find("#iso-beads").prop("checked", true);
			afficherForme("v");
		}
	} else {
		afficherOption('moulure', false);
		$('.mask-link').removeClass("hidden");
		$('.moulure-on').removeClass("hidden");
	}
	
}
function afficherCushion(node) {
	if (node.is(':checked') ) {
		afficherPlateau("coussin", true);
	} else {
		afficherPlateau("coussin", false);
	}
}
function afficherTable(node) {
	if (node.hasClass("checkbox-table-transparent")) {
		if (node.is(':checked') ) {
			$('.checkbox-table-smoked').find("input[type='checkbox']").prop("checked", false);
			afficherPlateau("transparent", true);
		} else {
			afficherPlateau("transparent", false);
		}
	} else if (node.hasClass("checkbox-table-smoked")) {
		if (node.is(':checked') ) {
			$('.checkbox-table-transparent').find("input[type='checkbox']").prop("checked", false);
			afficherPlateau("fume", true);
		} else {
			afficherPlateau("fume", false);
		}
	}
}
function choiceOption(node) {
	$(".choice-option").removeClass("on");
	if(node.hasClass('radio-straight-body')) {
		$('.checkbox-moulure').find("input[type='checkbox']").prop("checked", false);
		afficherForme("lisse");
		afficherOption('moulure', false);
		$('.mask-link').removeClass("hidden");
		$('.moulure-on').removeClass("hidden");
		node.addClass("on");
	} else if(node.hasClass('radio-iso-beads')){
		afficherForme("v");
		node.addClass("on");
	} else if(node.hasClass('radio-w-beads')){
		afficherForme("w");
		node.addClass("on");
	} else if(node.hasClass('radio-flat-beads')){
		afficherForme("plat");
		node.addClass("on");
	}
}
function linkContentPrinting(node) {
	if(node.hasClass("fa-link")) {
		node.removeClass("fa-link");
		node.addClass("fa-unlink");
		$('.moulure').trigger("click");
	} else {
		node.removeClass("fa-unlink");
		node.addClass("fa-link");
	}
}
function choiceFinishing(node) {
	if(node.hasClass('radio-mid-gloss')) {
		changerBrillance('gabarit_fut', false)
	} else if (node.hasClass('radio-mid-satin')) {
		changerBrillance('gabarit_fut', true)
	} else if (node.hasClass('radio-top-gloss')) {
		changerBrillance('couvercle_haut', false)
	} else if (node.hasClass('radio-top-satin')) {
		changerBrillance('couvercle_haut', true)
	} else if (node.hasClass('radio-bot-gloss')) {
		changerBrillance('couvercle_bas', false)
	} else if (node.hasClass('radio-bot-satin')) {
		changerBrillance('couvercle_bas', true)
	}
}



function showPanelLeft(target) {
	// let tab= $('.trigger-mdc-tab');
	let lBodyDp= target.attr('data-l-body-dp');
	let hBodyDp= target.attr('data-h-body-dp');
	let lCouvercleDp= target.attr('data-l-couvercle-dp');
	let hCouvercleDp= target.attr('data-h-couvercle-dp');
	let lBodySp= target.attr('data-l-body-sp');
	let hBodySp= target.attr('data-h-body-sp');
	let lCouvercleSp= target.attr('data-l-couvercle-sp');
	let hCouvercleSp= target.attr('data-h-couvercle-sp');
	let lLogoMiddleSp= target.attr('data-l-logo-middle-sp');
	let hLogoMiddleSp= target.attr('data-h-logo-middle-sp');
	let radioIsoBeads = $(".radio-iso-beads");
	let radioWBeads = $(".radio-w-beads");
	let radioFlatBeads = $(".radio-flat-beads");
	let eventListener=function(event) {
		let action=event.detail.action;
		if(action === "accept") {
			let fbx = target.attr('data-fbx');
			if (fbx !== "fut-8" && fbx !== "fut-7" && fbx !== "fut-6" && fbx !== "fut-5") {
				radioWBeads.addClass("hidden");
				if (radioWBeads.hasClass("on")) {
					radioIsoBeads.addClass("on");
					radioWBeads.removeClass("on");
					radioIsoBeads.find("#iso-beads").prop("checked", true);
					chargeFBX(fbx, "v");
				}
				
				$(".checkbox-table-smoked").addClass("hidden");
				$(".checkbox-table-transparent").addClass("hidden");
				$(".checkbox-cushion").removeClass("hidden");
				$(".checkbox-table").find("input[type='checkbox']").prop('checked', false);
				$(".checkbox-moulure").addClass("hidden");
				
			} else if (fbx !== "fut-3" && fbx !== "fut-4" && fbx !== "fut-9") {
				radioFlatBeads.addClass("hidden");
				radioIsoBeads.addClass("on");
				radioFlatBeads.removeClass("on");
				radioIsoBeads.find("#iso-beads").prop("checked", true);
				chargeFBX(fbx, "v");
				
			}
			
			if (fbx === "fut-3" || fbx === "fut-4" || fbx === "fut-9") {
				radioFlatBeads.removeClass("hidden");
				chargeFBX(fbx, "v");
			} else {
				radioFlatBeads.addClass("hidden");
				radioFlatBeads.removeClass("on");
				if (fbx === "fut-1" || fbx === "fut-2") {
					chargeFBX(fbx, "v");
				}
			}
			if (fbx === "fut-8" || fbx === "fut-7" || fbx === "fut-6" || fbx === "fut-5") {
				radioWBeads.removeClass("hidden");
				$(".checkbox-table-smoked").removeClass("hidden");
				$(".checkbox-table-transparent").removeClass("hidden");
				$(".checkbox-cushion").addClass("hidden");
				$(".checkbox-cushion").find("input[type='checkbox']").prop('checked', false);
				$(".checkbox-moulure").removeClass("hidden");
				if($(".checkbox-moulure").find("input[type='checkbox']").is(':checked')) {
					$('.mask-link').addClass("hidden");
					$('.moulure-on').addClass("hidden");
				} else {
					$('.mask-link').removeClass("hidden");
					$('.moulure-on').removeClass("hidden");
				}
			}
			if (fbx === "fut-1" || fbx === "fut-2") {
				$(".checkbox-moulure").addClass("hidden");
				$(".checkbox-moulure").find("input[type='checkbox']").prop('checked', false);
				$('.mask-link').removeClass("hidden");
				$('.moulure-on').removeClass("hidden");
				$('.checkbox-moulure').find(".mdc-checkbox__native-control").attr("checked", false);
				radioIsoBeads.find("#iso-beads").prop("checked", true);
				radioWBeads.addClass("hidden");
				radioIsoBeads.addClass("on");
			}
			
			$('.trigger-mdc-tab').find(".mdc-tab").removeClass("mdc-tab--active");
			$('.trigger-mdc-tab').find(".mdc-tab-indicator").removeClass("mdc-tab-indicator--active");
			if (fbx === target.find('.mdc-tab').attr('id')) {
				target.find("#"+fbx+"").addClass("mdc-tab--active");
				target.find("#"+fbx+"").find(".mdc-tab-indicator").addClass("mdc-tab-indicator--active");
			}
			$('#app-left-personnalisation').removeClass();
			$('#app-left-personnalisation').addClass(fbx);
			$("input[type='file']").val('');
			$("#app-left-personnalisation").find('img').attr('src', '');
			$("#app-left-personnalisation").find('img').addClass('hidden');
			$(".tier-masks").find(".tier").removeClass('file-active');
			$("#app-left-personnalisation").find('.delete-mask').addClass('hidden');
			$('.l-sp-body').text(lBodySp);
			$('.h-sp-body').text(hBodySp);
			$('.l-sp').text(lCouvercleSp);
			$('.h-sp').text(hCouvercleSp);
			$('.l-sp-body-logo-middle').text(lLogoMiddleSp);
			$('.h-sp-body-logo-middle').text(hLogoMiddleSp);
			if ($('.part-body').hasClass('visible')) {
				$('.l-dp-body').text(lBodyDp);
				$('.h-dp-body').text(hBodyDp);
			} else {
				$('.l-dp-couvercle').text(lCouvercleDp);
				$('.h-dp-couvercle').text(hCouvercleDp);
			}
		}
		
		mdcAlert.unlisten("MDCDialog:closing", eventListener);
	};
	$(mdcAlert["root_"]).find(".mdc-dialog__content").empty().append(target.attr("data-message"));
	mdcAlert.open();
	mdcAlert.listen("MDCDialog:closing", eventListener);

}

function choosePart(node) {
	let appContentActive=$(".app-content--active");
	if(node.hasClass("tab-top")) {
		if(bDigitalPrinting('tab-top') == true) {
			console.log('true-top');
			$('.tab-digital').trigger('click');
		} else {
			console.log('false-top');
			$('.tab-screen').trigger('click');
		}
		if($(".tab-screen").hasClass("mdc-tab--active")) {
			appContentActive.removeClass("app-content--active");
			screenCouvercleTop.addClass("app-content--active");
		} else {
			appContentActive.removeClass("app-content--active");
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-top").addClass("visible");
			digital.addClass("app-content--active");
		}
	} else if(node.hasClass("tab-body")) {
		if(bDigitalPrinting('tab-body') == true) {
			console.log('true-body');
			$('.tab-digital').trigger('click');
		} else {
			console.log('false-body');
			$('.tab-screen').trigger('click');
		}
		if($(".tab-screen").hasClass("mdc-tab--active")) {
			appContentActive.removeClass("app-content--active");
			screenBody.addClass("app-content--active");
		} else {
			appContentActive.removeClass("app-content--active");
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-body").addClass("visible");
			digital.addClass("app-content--active");
		}
	} else if(node.hasClass("tab-bot")) {
		if(bDigitalPrinting('tab-bot') == true) {
			console.log('true-bot');
			$('.tab-digital').trigger('click');
		} else {
			console.log('false-bot');
			$('.tab-screen').trigger('click');
		}
		if($(".tab-screen").hasClass("mdc-tab--active")) {
			appContentActive.removeClass("app-content--active");
			screenCouvercleBottom.addClass("app-content--active");
		} else {
			appContentActive.removeClass("app-content--active");
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-bot").addClass("visible");
			digital.addClass("app-content--active");
		}
	}
	
}

function deleteMask(node) {
	console.log(node.closest(".app-upload-mask").find(".delete-mask"));
	if (node.closest(".app-upload-mask").hasClass("mask-bottom")) {
		$(".tier-masks").find(".tier-bottom").removeClass('file-active');
		$(".mask-bottom").find("i").addClass('hidden');
		$(".file-part-bot").val('');
		changerMotif("motif_fut_bas", '', 1, 1)
	} else if (node.closest(".app-upload-mask").hasClass("mask-middle")) {
		$(".tier-masks").find(".tier-middle").removeClass('file-active');
		$(".mask-middle").find("i").addClass('hidden');
		$(".file-part-body").val('');
		changerMotif("motif_fut_milieu", '', 1, 1)
	} else if(node.closest(".app-upload-mask").hasClass("mask-top")) {
		$(".tier-masks").find(".tier-top").removeClass('file-active');
		$(".mask-top").find("i").addClass('hidden');
		$(".file-part-top").val('');
		changerMotif("motif_fut_haut", '', 1, 1)
	} else if(node.closest(".couvercle-color-container").hasClass("couvercle-top")) {
		$(".couvercle-top").find(".delete-mask").addClass('hidden');
		$(".couvercle-top").find(".file-part-top").val('');
		$(".couvercle-top").find("#picture-top").attr('src', '');
		$(".couvercle-top").find("#picture-top").addClass('hidden');
		changerMotif("motif_couvercle_haut", '', 1, 1)
	} else if(node.closest(".couvercle-color-container").hasClass("couvercle-bottom")) {
		$(".couvercle-bottom").find(".delete-mask").addClass('hidden');
		$(".couvercle-bottom").find(".file-part-bot").val('');
		$(".couvercle-bottom").find("#picture-bottom").attr('src', '');
		$(".couvercle-bottom").find("#picture-bottom").addClass('hidden');
		changerMotif("motif_couvercle_bas", '', 1, 1)
	}
}

function showContentPrinting(node) {
	let appContentActive=$(".app-content--active");
	if(node.hasClass("tab-digital")) {
		appContentActive.removeClass("app-content--active");
		digital.addClass("app-content--active");
		if($(".tab-top").hasClass("mdc-tab--active")) {
			afficher('couvercle_haut', true);
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-top").addClass("visible");
		} else if($(".tab-body").hasClass("mdc-tab--active")) {
			afficher('gabarit_fut', true);
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-body").addClass("visible");
		} else if($(".tab-bot").hasClass("mdc-tab--active")) {
			afficher('couvercle_bas', true);
			digital.find(".part-dp").removeClass("visible");
			digital.find(".part-bot").addClass("visible");
		}
	} else if(node.hasClass("tab-screen")) {
		digital.removeClass("app-content--active");
		if($(".tab-top").hasClass("mdc-tab--active")) {
			afficher('couvercle_haut', false);
			appContentActive.removeClass("app-content--active");
			screenCouvercleTop.addClass("app-content--active");
		} else if($(".tab-body").hasClass("mdc-tab--active")) {
			afficher('gabarit_fut', false);
			appContentActive.removeClass("app-content--active");
			screenBody.addClass("app-content--active");
		} else if($(".tab-bot").hasClass("mdc-tab--active")) {
			afficher('couvercle_bas', false);
			appContentActive.removeClass("app-content--active");
			screenCouvercleBottom.addClass("app-content--active");
		}
	}
}

function uploadMotif (e) {
	let input=e.target;
	let part= $(input).attr('data-pos-picture');
	let futId = $(input).closest('#app-left-personnalisation').attr('class');
	let reader=new FileReader();
	let largeurBody = $(".trigger-mdc-tab"+futId+"").attr('data-l-body-dp');
	let hauteurBody = $(".trigger-mdc-tab"+futId+"").attr('data-h-body-dp');
	let largeurCouvercle = $(".trigger-mdc-tab"+futId+"").attr('data-l-couvercle-dp');
	let hauteurCouvercle = $(".trigger-mdc-tab"+futId+"").attr('data-h-couvercle-dp');
	reader.onload=function() {
		let dataURL=reader.result;
		let output=document.getElementById("picture-"+part+"");
		$(output).removeClass("hidden");
		if ($(input).hasClass('file-part-body')) {
			changerMotif('gabarit_fut', dataURL, largeurBody, hauteurBody);
		} else if ($(input).hasClass('file-part-top')) {
			changerMotif('couvercle_haut', dataURL, largeurCouvercle, hauteurCouvercle);
		} else if ($(input).hasClass('file-part-bot')) {
			changerMotif('couvercle_bas', dataURL, largeurCouvercle, hauteurCouvercle);
			$("#text-file-"+part+"").text('');
		}
		
	};
	reader.readAsDataURL(input.files[0]);
}

function uploadMotifCouvercleMasks (e) {
	let input=e.target;
	let part= $(input).attr('data-pos-picture');
	let reader=new FileReader();
	let futId = $(input).closest('#app-left-personnalisation').attr('class');
	let largeurCouvercle = $(".trigger-mdc-tab"+futId+"").attr('data-l-couvercle-sp');
	let hauteurCouvercle = $(".trigger-mdc-tab"+futId+"").attr('data-h-couvercle-sp');
	reader.onload=function() {
		let dataURL=reader.result;
		let output=document.getElementById("picture-"+part+"");
		output.src=dataURL;
		$(output).removeClass("hidden");
		if ($(input).hasClass('file-part-top')) {
			changerMotif('motif_couvercle_haut', dataURL, largeurCouvercle, hauteurCouvercle);
			$(".couvercle-top").find("i").removeClass('hidden');
		} else if ($(input).hasClass('file-part-bot')) {
			changerMotif('motif_couvercle_bas', dataURL, largeurCouvercle, hauteurCouvercle);
			$(".couvercle-bottom").find("i").removeClass('hidden');
		}
		
	};
	reader.readAsDataURL(input.files[0]);
}

function uploadMotifFutMasks (e) {
	let input=e.target;
	let part= $(input).attr('data-pos-picture');
	let reader=new FileReader();
	let futId = $(input).closest('#app-left-personnalisation').attr('class');
	let largeurBody = $(".trigger-mdc-tab"+futId+"").attr('data-l-body-sp');
	let hauteurBody = $(".trigger-mdc-tab"+futId+"").attr('data-h-body-sp');
	let largeurLogoMidBody = $(".trigger-mdc-tab"+futId+"").attr('data-l-logo-middle-sp');
	let hauteurLogoMidBody = $(".trigger-mdc-tab"+futId+"").attr('data-h-logo-middle-sp');
	reader.onload=function() {
		let dataURL=reader.result;
		if ($(input).hasClass('file-part-top')) {
			changerMotif('motif_fut_haut', dataURL, largeurBody, hauteurBody);
			$(".tier-masks").find("."+part+"").addClass('file-active');
			$(".mask-top").find("i").removeClass('hidden');
		} else if ($(input).hasClass('file-part-bot')) {
			changerMotif('motif_fut_bas', dataURL, largeurBody, hauteurBody);
			$(".tier-masks").find("."+part+"").addClass('file-active');
			$(".mask-bottom").find("i").removeClass('hidden');
		} else if ($(input).hasClass('file-part-body')) {
			$(".tier-masks").find("."+part+"").addClass('file-active');
			changerMotif('motif_fut_milieu', dataURL, largeurLogoMidBody, hauteurLogoMidBody);
			// console.log(largeurLogoMidBody + hauteurLogoMidBody);
			$(".mask-middle").find("i").removeClass('hidden');
		}
		
	};
	reader.readAsDataURL(input.files[0]);
}
function readImage(e) {
	let input=e.target;
	let pictureId= $(input).attr('data-pos-picture');
	let reader=new FileReader();
	reader.onload=function() {
		let dataURL=reader.result;
		let output=document.getElementById("picture-"+pictureId+"");
		$("#text-file-"+pictureId+"").text('');
		output.src=dataURL;
	};
	reader.readAsDataURL(input.files[0]);
}

function showInfoBulle(node) {
	let message = node.attr('data-bulle');
	let container = $(".app-info-bulle");
	
	container.removeClass("hidden");
	$( "<div class='app-message-bulle'>"+message+"</div>" ).appendTo( ".app-info-bulle" );
	
	if (node.hasClass("video-3d")) {
		container.css('top', '50px');
		$(".app-message-bulle").css('margin-top', '8px');
	} else if (node.hasClass("screenshot-3d")) {
		container.css('top', '125px');
		$(".app-message-bulle").css('margin-top', '15px');
	} else {
		container.css('top', '-25px');
		$(".app-message-bulle").css('margin-top', '15px');
		
	}
}

$(document).ready(function() {
	mdcInit();
	
	$(page).on("click tap", "#app-left-personnalisation .branding .mdc-tab", function() {
		showContentPrinting($(this));
	}).on("click tap", "#app-left-personnalisation .fut-part .mdc-tab", function() {
		choosePart($(this));
	}).on("click tap", ".app-upload-mask .delete-mask, .couvercle-color-container .delete-mask", function() {
		deleteMask($(this));
	}).on("click tap", ".fa-link, .fa-unlink", function() {
		linkContentPrinting($(this));
	}).on("click tap", "#header-center-container .trigger-mdc-tab", function() {
		showPanelLeft($(this));
	}).on("change", "#app-left-personnalisation .app-file input[type='file']", function() {
		uploadMotif(event);
	}).on("change", "#app-left-personnalisation .couvercle-mask input[type='file']", function() {
		uploadMotifCouvercleMasks(event);
	}).on("change", "#app-left-personnalisation .tier-masks input[type='file']", function() {
		uploadMotifFutMasks(event);
	}).on("click tap", ".app-center-extra-control", function() {
		handleCenterExtraPanel($(this));
	}).on("click tap", ".choice-finishing .mdc-radio", function() {
		choiceFinishing($(this));
	}).on("click tap", ".choice-option", function() {
		choiceOption($(this));
	}).on("click tap", ".checkbox-moulure input[type='checkbox']", function() {
		afficherMoulure($(this));
	}).on("click tap", ".checkbox-cushion input[type='checkbox']", function() {
		afficherCushion($(this));
	}).on("click tap", ".checkbox-table input[type='checkbox']", function() {
		afficherTable($(this));
	}).on("click tap", ".checkbox-table input[type='checkbox']", function() {
		afficherTable($(this));
	}).on("mouseenter mouseleave", ".app-action-info ", function() {
		showInfoBulle($(this));
	}).on("mouseleave", ".app-action-info ", function() {
		$(".app-info-bulle").addClass("hidden");
		$( ".app-message-bulle" ).replaceWith( "" );
	}).on("submit", ".ajs", function(e) {
		e.preventDefault();
		if($(this)[0].reportValidity()) {
			ajaxSubmit($(this));
		}
	});
	
	
});
// COLOR PICKER
let source = $('.tier-color-button');
let allTier = $('.color-tier');
let botColor = $('.color-tier-bottom');
let midColor = $('.color-tier-middle');
let topColor = $('.color-tier-top');
function update(elem, picker) {
	console.log(elem);
	var code = elem.target;
	
	if (code.value.length) {
		picker.set(code.value);
		picker.fire("change", [code.value.slice(1)]);
	}
}

// set hooks for each source element
for (var i = 0, len = source.length; i < len; ++i) {
	let picker = new CP(source[i]), code = document.createElement('input');
	code.className = "color-code";
	code.pattern = '^#[A-Fa-f0-9]{6}$';
	code.type = 'text';
	code.value = '#aaaaaa';
	picker.set('rgb(170, 170, 170)');
	code.oncut = function(elem){ update(elem, picker);};
	code.onpaste = function(elem){ update(elem, picker);};
	code.onkeyup = function(elem){ update(elem, picker);};
	code.oninput = function(elem){ update(elem, picker);};
	allTier.css("background-color", code.value);
	picker.self.appendChild(code);
	(picker).on("change", function(color) {
		$('.color-code').val('#' + color);
		if (($('.app-link-bot-mid').hasClass("fa-link")) && ($('.app-link-mid-top').hasClass("fa-link"))) {
			allTier.css("background-color", '#' + color);
			changerCouleur('fut_milieu', '#' + color, false);
			changerCouleur('fut_haut', '#' + color, false);
			changerCouleur('fut_bas', '#' + color, false);
		} else if (($('.app-link-bot-mid').hasClass("fa-link")) && ($('.app-link-mid-top').hasClass("fa-unlink"))) {
			let tierColor = this.source.dataset.tier;
			botColor.addClass('bot-mid');
			midColor.addClass('bot-mid');
			if (tierColor === 'tier-middle' || tierColor === 'tier-bottom') {
				midColor.removeClass('mid-top');
				midColor.css("background-color", '#' + color);
				$(".container-no-mask").find('.bot-mid').css("background-color", '#' + color);
				changerCouleur('fut_bas', '#' + color, false);
				changerCouleur('fut_milieu', '#' + color, false);
			} else if (tierColor === 'tier-top') {
				topColor.removeClass('mid-top');
				topColor.css("background-color", '#' + color);
				changerCouleur('fut_haut', '#' + color, false);
			}
		} else if(($('.app-link-bot-mid').hasClass("fa-unlink")) && ($('.app-link-mid-top').hasClass("fa-link"))) {
			let tierColor = this.source.dataset.tier;
			botColor.removeClass('bot-mid');
			midColor.removeClass('bot-mid');
			midColor.addClass('mid-top');
			topColor.addClass('mid-top');
			if (tierColor === 'tier-bottom') {
				changerCouleur('fut_bas', '#' + color, false);
				botColor.css("background-color", '#' + color);
			} else if (tierColor === 'tier-middle' || tierColor === 'tier-top') {
				midColor.removeClass('bot-mid');
				$(".container-no-mask").find('.mid-top').css("background-color", '#' + color);
				changerCouleur('fut_milieu', '#' + color, false);
				changerCouleur('fut_haut', '#' + color, false);
			}
		} else if (($('.app-link-bot-mid').hasClass("fa-unlink")) && ($('.app-link-mid-top').hasClass("fa-unlink"))) {
			let tierColor = this.source.dataset.tier;
			if (tierColor === 'tier-middle') {
				midColor.removeClass('mid-top');
				midColor.css("background-color", '#' + color);
				changerCouleur('fut_milieu', '#' + color, false);
			} else if (tierColor === 'tier-top') {
				topColor.removeClass('mid-top');
				topColor.css("background-color", '#' + color);
				changerCouleur('fut_haut', '#' + color, false);
			}
			if (tierColor === 'tier-bottom') {
				botColor.removeClass('bot-mid');
				botColor.css("background-color", '#' + color);
				changerCouleur('fut_bas', '#' + color, false);
			}
		}
	});
	
}


// COLOR PICKER COUVERCLE no mask
let sourceCouvercleNoMask = $('.no-mask');

// set hooks for each source element
for (var i = 0, len = sourceCouvercleNoMask.length; i < len; ++i) {
	let pickerCouvercle = new CP(sourceCouvercleNoMask[i]), codeCouvercle = document.createElement('input');
	codeCouvercle.className = "color-code-couvercle";
	codeCouvercle.pattern = '^#[A-Fa-f0-9]{6}$';
	codeCouvercle.type = 'text';
	codeCouvercle.value = '#aaaaaa';
	pickerCouvercle.set('rgb(170, 170, 170)');
	codeCouvercle.oncut = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.onpaste = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.onkeyup = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.oninput = function(elem){ update(elem, pickerCouvercle);};
	sourceCouvercleNoMask.css("background-color", codeCouvercle.value);
	pickerCouvercle.self.appendChild(codeCouvercle);
	(pickerCouvercle).on("change", function(color) {
		// $('.color-code').val('#' + color);
		let tierColorCouvercle = this.source.dataset.tier;
		 if (tierColorCouvercle === "tier-bot") {
			changerCouleur('couvercle_bas', '#' + color, false);
		} else if (tierColorCouvercle === "tier-top") {
			changerCouleur('couvercle_haut', '#' + color, false);
		}
		$('.color-code-couvercle').val('#' + color);
		this.source.style.backgroundColor = '#' + color;
	});
}

// COLOR PICKER COUVERCLE mask
let sourceCouvercleMask = $('.with-mask');

// set hooks for each source element
for (var i = 0, len = sourceCouvercleMask.length; i < len; ++i) {
	let pickerCouvercle = new CP(sourceCouvercleMask[i]), codeCouvercle = document.createElement('input');
	codeCouvercle.className = "color-code-couvercle";
	codeCouvercle.pattern = '^#[A-Fa-f0-9]{6}$';
	codeCouvercle.type = 'text';
	codeCouvercle.value = '#ffffff';
	pickerCouvercle.set('rgb(255, 255, 255)');
	codeCouvercle.oncut = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.onpaste = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.onkeyup = function(elem){ update(elem, pickerCouvercle);};
	codeCouvercle.oninput = function(elem){ update(elem, pickerCouvercle);};
	sourceCouvercleMask.css("background-color", codeCouvercle.value);
	pickerCouvercle.self.appendChild(codeCouvercle);
	(pickerCouvercle).on("change", function(color) {
		// $('.color-code').val('#' + color);
		let tierColorCouvercle = this.source.dataset.tier;
		if (tierColorCouvercle === "tier-bot-mask") {
			changerCouleur('motif_couvercle_bas', '#' + color, false);
		} else if (tierColorCouvercle === "tier-top-mask") {
			changerCouleur('motif_couvercle_haut', '#' + color, false);
		}
		$('.color-code-couvercle').val('#' + color);
		this.source.style.backgroundColor = '#' + color;
	});
}

// COLOR PICKER MASKS
let sourceMasks = $('.tier-button-masks');
let allTierMasks = $('.color-tier-masks');
let botColorMasks = $('.color-tier-masks-bottom');
let midColorMasks = $('.color-tier-masks-middle');
let topColorMasks = $('.color-tier-masks-top');

// set hooks for each source element
for (var i = 0, len = source.length; i < len; ++i) {
	let pickerMasks = new CP(sourceMasks[i]), codeMasks = document.createElement('input');
	codeMasks.className = "color-code-masks";
	codeMasks.pattern = '^#[A-Fa-f0-9]{6}$';
	codeMasks.type = 'text';
	codeMasks.value = '#ffffff';
	pickerMasks.set('rgb(255, 255, 255)');
	codeMasks.oncut = function(elem){ update(elem, pickerMasks);};
	codeMasks.onpaste = function(elem){ update(elem, pickerMasks);};
	codeMasks.onkeyup = function(elem){ update(elem, pickerMasks);};
	codeMasks.oninput = function(elem){ update(elem, pickerMasks);};
	allTierMasks.css("background-color", codeMasks.value);
	pickerMasks.self.appendChild(codeMasks);
	(pickerMasks).on("change", function(color) {
		let tierColorMasks = this.source.dataset.tier;
		$('.color-code-masks').val('#' + color);
		if (($('.app-link-masks-bot-mid').hasClass("fa-link")) && ($('.app-link-masks-mid-top').hasClass("fa-link"))) {
			allTierMasks.css("background-color", '#' + color);
			changerCouleur('motif_fut_milieu', '#' + color, false);
			changerCouleur('motif_fut_haut', '#' + color, false);
			changerCouleur('motif_fut_bas', '#' + color, false);
		} else if (($('.app-link-masks-bot-mid').hasClass("fa-link")) && ($('.app-link-masks-mid-top').hasClass("fa-unlink"))) {
			let tierColorMasks = this.source.dataset.tier;
			botColorMasks.addClass('bot-mid');
			midColorMasks.addClass('bot-mid');
			if (tierColorMasks === 'tier-middle' || tierColorMasks === 'tier-bottom') {
				midColorMasks.removeClass('mid-top');
				midColorMasks.css("background-color", '#' + color);
				$(".container-mask").find('.bot-mid').css("background-color", '#' + color);
				changerCouleur('motif_fut_milieu', '#' + color, false);
				changerCouleur('motif_fut_bas', '#' + color, false);
			} else if (tierColorMasks === 'tier-top') {
				topColorMasks.removeClass('mid-top');
				topColorMasks.css("background-color", '#' + color);
				changerCouleur('motif_fut_haut', '#' + color, false);
			}
		} else if(($('.app-link-masks-bot-mid').hasClass("fa-unlink")) && ($('.app-link-masks-mid-top').hasClass("fa-link"))) {
			let tierColorMasks = this.source.dataset.tier;
			botColorMasks.removeClass('bot-mid');
			midColorMasks.removeClass('bot-mid');
			midColorMasks.addClass('mid-top');
			topColorMasks.addClass('mid-top');
			if (tierColorMasks === 'tier-bottom') {
				botColorMasks.css("background-color", '#' + color);
				changerCouleur(tierColorMasks, color, false);
			} else if (tierColorMasks === 'tier-middle' || tierColorMasks === 'tier-top') {
				$(".container-mask").find('.mid-top').css("background-color", '#' + color);
				changerCouleur('motif_fut_milieu', '#' + color, false);
				changerCouleur('motif_fut_haut', '#' + color, false);
			}
		} else if (($('.app-link-masks-bot-mid').hasClass("fa-unlink")) && ($('.app-link-masks-mid-top').hasClass("fa-unlink"))) {
			let tierColorMasks = this.source.dataset.tier;
			if (tierColorMasks === 'tier-middle') {
				midColorMasks.removeClass('mid-top');
				midColorMasks.css("background-color", '#' + color);
				changerCouleur('motif_fut_milieu', '#' + color, false);
			} else if (tierColorMasks === 'tier-top') {
				topColorMasks.removeClass('mid-top');
				topColorMasks.css("background-color", '#' + color);
				changerCouleur('motif_fut_haut', '#' + color, false);
			}
			if (tierColorMasks === 'tier-bottom') {
				botColorMasks.removeClass('bot-mid');
				botColorMasks.css("background-color", '#' + color);
				changerCouleur('motif_fut_bas', '#' + color, false);
			}
		}
	});
}

$(".radio-iso-beads").find("#iso-beads").prop("checked", true);






